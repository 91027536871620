import React, { useState, useEffect } from 'react';
import logo from './Logo_Final.png';
import './App.css';

function App() {
  const fullText = "Coming soon...";
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayedText((prev) => prev + fullText[index]);

      setIndex((prevIndex) => {
        if (prevIndex === fullText.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });

      if (index === fullText.length - 1) {
        setDisplayedText("");
      }
    }, 200);

    return () => clearInterval(interval);
  }, [index, fullText]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {/* Fijamos un ancho para evitar que el texto mueva el logo */}
        <div style={{ width: '12ch', height: '2em' }}>
          <span>{displayedText}</span>
        </div>
      </header>
    </div>
  );
}

export default App;

